import * as React from 'react';

import { FreeTrialExpiredBanner } from '@/components/free-trial-expired-banner';
import type { FreeTrialExpiredBannerProps } from '@/components/free-trial-expired-banner';
import SubscriptionPlans from '@/components/subscription-plans';

export interface SelfServiceHomeTrialExpiredProps
  extends FreeTrialExpiredBannerProps {}

const SelfServiceHomeTrialExpired: React.FC<
  SelfServiceHomeTrialExpiredProps
> = ({ freeTrialDaysLeft }) => (
  <>
    <FreeTrialExpiredBanner freeTrialDaysLeft={freeTrialDaysLeft} />
    <SubscriptionPlans />
  </>
);

export default SelfServiceHomeTrialExpired;
