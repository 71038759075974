import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import classNames from 'classnames';
import Router, { useRouter } from 'next/router';

import { AccountTierType } from '@evenfinancial/finance-client';
import type {
  SaasMigrationSetting,
  SaasPlan,
  SaasSubscription,
} from '@evenfinancial/finance-client';

import type { SubscriptionPlanType } from '@portal/ui-lib';
import { SubscriptionPlanCard } from '@portal/ui-lib';

import ConfirmationModal from '@/components/confirmation-modal';
import { getPlans } from '@/pages/self-service/account/tabs/subscription/states/free-subscription';
import {
  selectLastPurchasedSubscription,
  selectSaasPlansForAccountSubscription,
} from '@/resources/saas/selects';
import { getPreviousTiers } from '@/resources/self-service/payment/util-methods';
import {
  selectIsFirstRolloutOnboarding,
  selectIsImplementationFeeWaived,
} from '@/resources/self-service/selects';
import { isPermanentTrial } from '@/resources/supply-migration/helpers';
import { selectSaasMigrationSettings } from '@/resources/supply-migration/selects';

const { Text } = Typography;

export interface SubscriptionPlansProps {
  currentPlan?: SaasPlan;
  currentSubscription?: SaasSubscription;
}

const getAvailablePlans = (
  planTypes: SubscriptionPlanType[],
  currentPlan?: SaasPlan,
  currentSubscription?: SaasSubscription,
  supplyMigration?: SaasMigrationSetting
) => {
  const tiersToRemove: AccountTierType[] = [];

  const showTier = (
    currentTier: AccountTierType,
    previousTier: AccountTierType | undefined
  ) =>
    currentPlan?.tier === previousTier ||
    (currentPlan?.tier === currentTier && currentSubscription?.deletedAt);

  const isPermanent = isPermanentTrial(supplyMigration!);

  const showStarter = showTier(AccountTierType.Starter, undefined);

  const showGrowth =
    showStarter || showTier(AccountTierType.Growth, AccountTierType.Starter);

  const showScale =
    showGrowth || showTier(AccountTierType.Scale, AccountTierType.Growth);

  if (!showStarter) {
    tiersToRemove.push(AccountTierType.Starter);
  }

  if (!showGrowth) {
    tiersToRemove.push(AccountTierType.Growth);
  }

  if (!showScale) {
    tiersToRemove.push(AccountTierType.Scale);
  }

  if (supplyMigration?.tier) {
    tiersToRemove.push(...getPreviousTiers(supplyMigration.tier));

    if (isPermanent) {
      tiersToRemove.push(supplyMigration.tier);
    }
  }

  return planTypes.map((planType) => {
    if (!tiersToRemove.includes(planType.id)) {
      return planType;
    }

    const actionButtonTitle =
      planType.id === currentPlan?.tier ? 'Current plan' : 'Unavailable';

    const isDisabled = planType.id !== currentPlan?.tier;

    return {
      ...planType,
      actionButtonTitle,
      cardClassName: `${planType.cardClassName} unavailable`,
      isDisabled,
      onActionButtonClick: undefined,
    };
  });
};

const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  currentPlan,
  currentSubscription,
}) => {
  const firstRolloutOnboarding = useSelector(selectIsFirstRolloutOnboarding);
  const implementationFeeWaived = useSelector(selectIsImplementationFeeWaived);
  const lastPurchasedSubscription = useSelector(
    selectLastPurchasedSubscription
  );
  const saasPlans = useSelector(selectSaasPlansForAccountSubscription);
  const supplyMigration = useSelector(selectSaasMigrationSettings);

  const [growthPlanModalVisible, setGrowthPlanModalVisible] = useState(false);
  const {
    query: { id: integrationId, submit },
  } = useRouter();
  const isSubmitForReview: boolean = Boolean(submit);
  const findMigrationPlan =
    !currentSubscription &&
    Boolean(supplyMigration) &&
    !isPermanentTrial(supplyMigration);
  const plans = getPlans(
    saasPlans,
    implementationFeeWaived,
    firstRolloutOnboarding,
    findMigrationPlan,
    supplyMigration,
    currentSubscription,
    currentPlan,
    lastPurchasedSubscription,
    integrationId as string,
    isSubmitForReview
  );

  const plansAvailable = getAvailablePlans(
    plans,
    currentPlan,
    currentSubscription,
    supplyMigration
  );

  const growthPlan = plansAvailable.find(
    (plan) => plan.id === AccountTierType.Growth
  );

  if (growthPlan && currentPlan?.tier === AccountTierType.Starter) {
    growthPlan.actionButtonTitle = 'Upgrade';
    growthPlan.onActionButtonClick = () => {
      setGrowthPlanModalVisible(true);
    };
  }

  return (
    <>
      <ConfirmationModal
        cancelText="Close"
        okText="Next"
        title="Upgrade to growth"
        visible={growthPlanModalVisible}
        onCancel={() => setGrowthPlanModalVisible(false)}
        onOk={() => {
          Router.push(`/purchase-plan?plan=${AccountTierType.Growth}`);
        }}
      >
        <Text>
          Upgrade will cancel your existing subscription once you purchase a new
          plan. If you had leftover amount from existing plan that was prepaid,
          it will be deducted from your total when you purchase the new plan.
        </Text>
      </ConfirmationModal>
      {isSubmitForReview && (
        <div className="subscription-text">
          <Text>
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ height: 16, width: 16 }}
            />{' '}
            You must purchase a plan before submitting your integration for
            review.
          </Text>
        </div>
      )}
      <div className="subscription-plans">
        {plansAvailable.map((plan, index) => (
          <SubscriptionPlanCard
            key={index}
            currentPlan={currentPlan}
            plan={plan}
          />
        ))}
      </div>
      <div className="integration-options">
        <div className="integration-options-header">Integration Fees</div>
        <div className="integration-options-body">
          {plans.map(({ optionIcon: Icon, optionName, optionFee }, index) => (
            <div key={index} className="integration-option">
              <p
                className={classNames({
                  waived: implementationFeeWaived && optionName !== 'Native',
                })}
              >
                {optionFee}
              </p>
              <div>
                <React.Fragment>{Icon}</React.Fragment>
                <span>
                  {optionName}
                  <sup>4</sup>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="subscription-plans-footnote">
        <ol>
          <li>
            Other business models and terms applicable per financial product
            vertical and tier
          </li>
          <li>Implementation fee applies per-integration</li>
          <li>Fee applies for additional seats</li>
          <li>
            Each integration must receive Compliance approval prior to going
            live. Any requested changes must be implemented and then reviewed by
            Even’s Compliance team. Should you not wish to continue or make the
            requested changes, your subscription will terminate. The Monthly
            Platform Fee is nonrefundable.
          </li>
        </ol>
      </div>
    </>
  );
};

export default SubscriptionPlans;
