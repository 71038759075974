import * as React from 'react';
import type { ReactNode } from 'react';

export interface DetailsLayoutProps extends React.PropsWithChildren<{}> {
  className?: string;
  sidebarContent?: ReactNode;
}

export const LayoutWithSidebar: React.FC<DetailsLayoutProps> = ({
  className,
  sidebarContent,
  children,
}) => (
  <div className={`layout-with-sidebar ${className || ''}`}>
    <div className="main-content">{children}</div>
    <div className="sidebar-content">{sidebarContent}</div>
  </div>
);
