import { createSelector } from 'reselect';

import type { PayableSummary } from '@evenfinancial/finance-client';

import { selectAccounts } from '@portal/store/dist/account/selectors';

import { selectPayables } from '@/resources/payables/selects';

export interface InvoiceRow extends PayableSummary {
  accountName: string;
  accountUuid?: string;
  activityPeriod?: Date[];
}

export const invoicesSelector = createSelector(
  [selectPayables, selectAccounts],
  (invoices, accounts) => {
    return invoices.map((invoice) => {
      const account = accounts.find((a) => a.id === invoice.accountId);

      return {
        ...invoice,
        accountUuid: account?.uuid,
        activityPeriod: [
          new Date(invoice.startDate),
          new Date(invoice.endDate),
        ],
        status: invoice.reconciliationType,
      } as InvoiceRow;
    });
  }
);

export const latestInvoicesSelector = createSelector(
  [invoicesSelector],
  (invoices) => {
    return invoices?.length >= 3 ? invoices.slice(0, 3) : invoices;
  }
);
