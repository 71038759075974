import * as React from 'react';
import { useState } from 'react';

import { Typography } from 'antd';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal/Modal';

import type { HippoVideo } from '@portal/api-models';

import HippoVideoEmbed from '@/components/hippo-video-embed';

const { Text, Title } = Typography;

export interface AcceptTosForm {
  tosAccepted: boolean;
}

interface GettingStartedModalProps {
  closeModal: () => void;
  title: string;
  video?: HippoVideo;
}

const GettingStartedModal: React.FC<GettingStartedModalProps> = ({
  title,
  video,
  closeModal,
}) => {
  const [visible, setVisible] = useState(true);

  return (
    <div className="welcome-to-control-center">
      <Modal
        className="welcome-to-control-center-modal"
        closable={false}
        footer={[
          <Button
            key="get-started"
            className={'get-started-btn'}
            type="primary"
            onClick={() => {
              setVisible(false);
              closeModal();
            }}
          >
            Ok, got it
          </Button>,
        ]}
        title={title}
        visible={visible}
        width={790}
      >
        {video && <HippoVideoEmbed embedUrl={video.embed_url} />}
        <Title className="video-title" level={4}>
          {video?.title}
        </Title>
        <Text>{video?.description}</Text>
      </Modal>
    </div>
  );
};

export default GettingStartedModal;
