import * as React from 'react';

import { faCartShopping } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'antd/lib/alert';

export interface FreeTrialExpiredBannerProps {
  freeTrialDaysLeft: number;
}

export const FreeTrialExpiredBanner: React.FC<FreeTrialExpiredBannerProps> = ({
  freeTrialDaysLeft,
}) => {
  return (
    <Alert
      closable
      showIcon
      className="free-trial-expired-banner"
      description="Your 30-day trial has expired. To regain access to your integration and continue using Control Center, activate one of the following plans."
      icon={
        <FontAwesomeIcon
          className="free-trial-expired-banner-icon"
          icon={faCartShopping}
        />
      }
      message={
        <div className="message">
          <span className="message-title">Trial Expired</span>
          <span className="message-note">
            {freeTrialDaysLeft < -1 ? (
              <span>{`${-freeTrialDaysLeft} days ago`}</span>
            ) : (
              <span>{`${-freeTrialDaysLeft} day ago`}</span>
            )}
          </span>
        </div>
      }
      type="warning"
    />
  );
};
