import type { UploadFile } from 'antd/lib/upload/interface';

export const partnerLogoFileSizeValidator = {
  message: 'File size is larger than 3MB.',
  validator: (value: any) => {
    return value?.fileList?.[0]?.size <= 3 * 1024 * 1024;
  },
};

export const fileTypeValidator = {
  message: 'File type is unsupported.',
  validator: (value: any) => {
    const supportedTyes = ['.pdf'];

    const isFileTypeSupported = value?.fileList.some((file: UploadFile) =>
      supportedTyes.some((type: string) => file.name.includes(type))
    );

    return isFileTypeSupported;
  },
};

export const fileStatusValidator = {
  message: 'File is not ready yet. Please wait!',
  validator: (value: any) => {
    return value?.fileList?.[0]?.status !== 'uploading';
  },
};

export const fileRequiredValidator = {
  message: '"Upload W9" is a required field',
  validator: (value: any) => {
    return value && value.fileList.length > 0;
  },
};
