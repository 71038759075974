import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { IntegrationType } from '@evenfinancial/auth-client';
import { AccountTierType } from '@evenfinancial/finance-client';

import { client } from '@portal/api-client';

import ConfirmationModal from '@/components/confirmation-modal';
import { DrawerForm } from '@/components/form/drawer-form';
import { PortalFormAction } from '@/components/form/portal-form/components/form-actions';
import { IntegrationsTableDataSelector } from '@/resources/integrations/selectors';
import { partnerPageProprietaryDataSelector } from '@/resources/partner-page/selectors';
import { saasUserPlanSelector } from '@/resources/saas/selectors';
import { selectSaasUserTrial } from '@/resources/saas/selects';
import {
  integrationCreateFormFields,
  integrationProductTypeOptions,
  integrationTypeOptions,
} from '@/resources/self-service/integrations/create-form';
import type { IntegrationCreateForm } from '@/resources/self-service/integrations/create-form';
import { integrationProductReachedLimit } from '@/resources/self-service/integrations/util';
import { getIntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import type { IntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import { Router } from '@/routes';
import { getApplicationConfiguration } from '@/store/config';
import {
  partnerPageCreateIntegrationRequestAction,
  toggleIntegrationCreate,
} from '@/store/partner-page/actions';

export interface IntegrationCreateProps {
  integration?: IntegrationCreateForm;
  lockProductType?: boolean;
}

export const IntegrationCreate: React.FC<IntegrationCreateProps> = ({
  integration,
  lockProductType,
}) => {
  const dispatch = useDispatch();
  const { integrationCreateVisible: visible } = useSelector(
    partnerPageProprietaryDataSelector,
    shallowEqual
  );

  const integrations = useSelector(IntegrationsTableDataSelector);
  const saasUserPlan = useSelector(saasUserPlanSelector);
  const saasUserTrial = useSelector(selectSaasUserTrial);
  const configuration = useSelector(getApplicationConfiguration);

  const validateKey = useCallback(
    async (partnerPageKey: string) => {
      if (configuration.IS_SERVER) {
        return;
      }
      return client.get<boolean>('/partner_page/key/validation', {
        params: {
          partnerPageKey,
        },
      });
    },
    [configuration]
  );
  const defaultIntegrationType =
    integration?.integrationType &&
    getIntegrationCategory(integration?.integrationType);

  const [integrationType, setIntegrationType] = useState<
    IntegrationCategory | undefined
  >(defaultIntegrationType);

  const isStarter = saasUserPlan?.tier === AccountTierType.Starter;
  const isLimited = !saasUserPlan;

  const integrationTypeList = isStarter
    ? [IntegrationType.MpsPartnerPageEven]
    : integrationTypeOptions;

  const integrationLimitReached = useMemo(
    () =>
      isLimited &&
      integrationTypeList.every((integrationType) =>
        integrationProductTypeOptions.every(() =>
          integrationProductReachedLimit(
            integrations,
            getIntegrationCategory(integrationType),
            saasUserPlan,
            saasUserTrial
          )
        )
      ),
    [integrations, saasUserPlan, saasUserTrial]
  );

  const toggleDrawer = () => dispatch(toggleIntegrationCreate());

  const createRequest = (form: IntegrationCreateForm) =>
    dispatch(partnerPageCreateIntegrationRequestAction.request(form));

  const fields = useMemo(() => {
    return integrationCreateFormFields(
      integrations,
      toggleDrawer,
      validateKey,
      integrationType,
      saasUserPlan,
      integration?.productType,
      lockProductType,
      saasUserTrial
    );
  }, [
    integrations,
    toggleDrawer,
    validateKey,
    integrationType,
    saasUserPlan,
    integration?.productType,
    lockProductType,
    saasUserTrial,
  ]);

  return integrationLimitReached ? (
    <ConfirmationModal
      className="user-confirmation-modal"
      okText="Purchase Now"
      title="Purchase a Subscription"
      visible={visible}
      onCancel={toggleDrawer}
      onOk={() => {
        toggleDrawer();
        Router.push('/account/upgrade');
      }}
    >
      Please purchase a subscription to create more integrations.
    </ConfirmationModal>
  ) : (
    <DrawerForm
      key="portalCreateForm"
      validateAllOnChange
      className="integration-create"
      defaultValues={integration}
      fields={fields}
      isLegacyPortalForm={false}
      title="Create New Integration"
      toggleDrawer={toggleDrawer}
      type={PortalFormAction.Create}
      visible={visible}
      onCancel={() => setIntegrationType(undefined)}
      onChange={(list) => {
        if (
          list.integrationType &&
          integrationType !== getIntegrationCategory(list.integrationType)
        ) {
          setIntegrationType(() =>
            getIntegrationCategory(list.integrationType)
          );
        }
      }}
      onSubmit={(form) => {
        createRequest(form);
      }}
    />
  );
};
