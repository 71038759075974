import * as React from 'react';

import {
  faCode,
  faCube,
  faDisplayCode,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import startCase from 'lodash/startCase';
import Router from 'next/router';

import { AccountTierType, SaasFeeReason } from '@evenfinancial/finance-client';
import type {
  SaasMigrationSetting,
  SaasPlan,
  SaasSubscription,
} from '@evenfinancial/finance-client';

import { moneyFormatter } from '@portal/common';
import type { SubscriptionPlanType } from '@portal/ui-lib';
import { ActionButtonType } from '@portal/ui-lib';

import SubscriptionPlans from '@/components/subscription-plans';
import {
  currentPlanSubscriptionData,
  findDefaultSaasPlanByWhitelist,
  getSaasFeeReasonPrice,
} from '@/resources/self-service/payment/util-methods';

const iconStyle = { height: 'auto', width: 24 };
const IconPartnerPage = () => (
  <FontAwesomeIcon icon={faDisplayCode} style={iconStyle} />
);
const IconEmbed = () => <FontAwesomeIcon icon={faCube} style={iconStyle} />;
const IconNative = () => <FontAwesomeIcon icon={faCode} style={iconStyle} />;

export const getPlans = (
  saasPlans: SaasPlan[],
  implementationFeeWaived: boolean,
  firstRolloutOnboarding: boolean,
  findMigrationPlan: boolean,
  supplyMigration?: SaasMigrationSetting,
  currentSubscription?: SaasSubscription,
  currentPlan?: SaasPlan,
  lastPurchasedSubscription?: SaasSubscription,
  integrationId?: string,
  submit?: boolean
): SubscriptionPlanType[] => {
  const starterTierPlan = findDefaultSaasPlanByWhitelist(
    saasPlans,
    AccountTierType.Starter,
    implementationFeeWaived,
    firstRolloutOnboarding,
    findMigrationPlan && supplyMigration?.tier === AccountTierType.Starter
  );

  const growthTierPlan = findDefaultSaasPlanByWhitelist(
    saasPlans,
    AccountTierType.Growth,
    implementationFeeWaived,
    firstRolloutOnboarding,
    findMigrationPlan && supplyMigration?.tier === AccountTierType.Growth
  );

  const migrationTierPlan =
    !currentSubscription &&
    supplyMigration &&
    findDefaultSaasPlanByWhitelist(
      saasPlans,
      supplyMigration.tier,
      implementationFeeWaived,
      firstRolloutOnboarding,
      findMigrationPlan
    );

  const currentPlanDisplayData =
    supplyMigration && currentPlan
      ? currentPlanSubscriptionData(currentPlan)
      : undefined;

  const isMigrationScaleUser = supplyMigration?.tier === AccountTierType.Scale;

  let showPickAPlan = false;

  if (isMigrationScaleUser && !lastPurchasedSubscription) {
    showPickAPlan = true;
  }

  const submitQuery =
    integrationId && submit ? `&id=${integrationId}&submit=true` : '';

  return [
    {
      actionButtonTitle: 'Pick Plan',
      actionButtonType: ActionButtonType.PRIMARY,
      cardClassName: 'starter',
      features: {
        dedicatedPartnerManager: false,
      },
      id: AccountTierType.Starter,
      integrations: [
        {
          icon: faDisplayCode,
          title: 'Partner Page',
        },
      ],
      name: startCase(AccountTierType.Starter),
      onActionButtonClick: () => {
        Router.push(
          `/purchase-plan?plan=${AccountTierType.Starter}${submitQuery}`
        );
      },
      optionFee: starterTierPlan
        ? moneyFormatter(
            currentPlanDisplayData?.partnerPageImplementationFee ??
              getSaasFeeReasonPrice(
                SaasFeeReason.PartnerPageImplementation,
                migrationTierPlan || starterTierPlan
              )
          )
        : 'n/a',
      optionIcon: IconPartnerPage,
      optionName: 'Partner Page',
      planFee: starterTierPlan
        ? moneyFormatter(
            currentPlanDisplayData?.monthlyFeeStarter ||
              starterTierPlan.monthlyFee
          )
        : 'n/a',
      pricing: 'You Earn Up To $300 Per-Lead',
      productOffersLevel: 'Limited',
      seatsNumber: starterTierPlan?.seatLimit?.toString() || '1',
    },
    {
      actionButtonTitle: 'Pick Plan',
      actionButtonType: ActionButtonType.PRIMARY,
      cardClassName: 'growth',
      features: {
        dedicatedPartnerManager: false,
      },
      id: AccountTierType.Growth,
      integrations: [
        {
          icon: faDisplayCode,
          title: 'Partner Page',
        },
        {
          icon: faCube,
          title: 'Embed',
        },
      ],
      name: startCase(AccountTierType.Growth),
      onActionButtonClick: () => {
        Router.push(
          `/purchase-plan?plan=${AccountTierType.Growth}${submitQuery}`
        );
      },
      optionFee: growthTierPlan
        ? moneyFormatter(
            currentPlanDisplayData?.embedImplementationFee ??
              getSaasFeeReasonPrice(
                SaasFeeReason.EmbedImplementation,
                migrationTierPlan || growthTierPlan
              )
          )
        : 'n/a',
      optionIcon: IconEmbed,
      optionName: 'Embed',
      planFee: growthTierPlan
        ? moneyFormatter(
            currentPlanDisplayData?.monthlyFeeGrowth ||
              growthTierPlan.monthlyFee
          )
        : 'n/a',
      pricing: 'You Earn Up To $300 Per-Lead',
      productOffersLevel: 'Advanced',
      seatsNumber: growthTierPlan?.seatLimit?.toString() || '2',
    },
    {
      actionButtonTertiary: true,
      actionButtonTitle: showPickAPlan ? 'Pick Plan' : 'Contact Us',
      actionButtonType: ActionButtonType.OUTLINED,
      cardClassName: 'scale',
      features: {
        dedicatedPartnerManager: true,
      },
      id: AccountTierType.Scale,
      integrations: [
        {
          icon: faDisplayCode,
          title: 'Partner Page',
        },
        {
          icon: faCube,
          title: 'Embed',
        },
        {
          icon: faCode,
          title: 'Native',
        },
      ],
      name: startCase(AccountTierType.Scale),
      onActionButtonClick: () => {
        showPickAPlan
          ? Router.push(
              `/purchase-plan?plan=${AccountTierType.Scale}${submitQuery}`
            )
          : Router.push('/account/upgrade-scale');
      },
      optionFee: 'Contact Us',
      optionIcon: IconNative,
      optionName: 'Native',
      planFee:
        isMigrationScaleUser && migrationTierPlan
          ? moneyFormatter(migrationTierPlan.monthlyFee)
          : 'Custom Pricing',
      pricing: 'Custom',
      productOffersLevel: 'Full',
      seatsNumber: isMigrationScaleUser ? 'Unlimited' : 'Custom',
    },
  ];
};

const FreeSubscriptionState: React.FC = () => {
  return <SubscriptionPlans />;
};

export default FreeSubscriptionState;
