import type { ParsedUrlQuery } from 'querystring';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'antd';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useRouter } from 'next/router';
import type { ActionType } from 'typesafe-actions';

import type {
  SaasPlan,
  SaasSubscription,
  SaasTrial,
} from '@evenfinancial/finance-client';

import type {
  HippoVideo,
  PortalSaasMigrationSetting,
  PortalSubAccount,
  TempAccount,
} from '@portal/api-models';
import * as accountActions from '@portal/store/dist/account/actions';
import { selectFirstAccount } from '@portal/store/dist/account/selectors';
import * as payableActions from '@portal/store/dist/payable/actions';
import { getTutorialVideoDetails } from '@portal/store/dist/tutorials';
import { TutorialVideoType } from '@portal/store/dist/tutorials/types';
import { viewContextSelector } from '@portal/store/dist/view-context/selectors';

import GettingStartedModal from '@/components/getting-started-modal';
import { LayoutWithSidebar } from '@/components/layout-with-sidebar';
import { RightSidebarContent } from '@/components/right-sidebar-content';
import { SelfServiceHomeRegular } from '@/components/self-service/home/index-regular';
import SelfServiceHomeTrialExpired from '@/components/self-service/home/index-trial-expired';
import { IntegrationCreate as CreateDrawer } from '@/components/self-service/integrations/create';
import { IntegrationsTableDataSelector } from '@/resources/integrations/selectors';
import type { IntegrationRow } from '@/resources/integrations/types';
import {
  invoicesSelector,
  latestInvoicesSelector,
} from '@/resources/latest-invoices/selectors';
import type { InvoiceRow } from '@/resources/latest-invoices/selectors';
import { getFreeTrialDaysLeft } from '@/resources/saas/helpers';
import {
  saasUserPlanSelector,
  saasUserTrialSelector,
} from '@/resources/saas/selectors';
import {
  selectSaasShowGettingStartedModal,
  selectSaasSubscription,
} from '@/resources/saas/selects';
import { selfServiceDataSelector } from '@/resources/self-service/selectors';
import type { SelfServiceData } from '@/resources/self-service/types';
import { isPermanentTrial } from '@/resources/supply-migration/helpers';
import { selectSaasMigrationSettings } from '@/resources/supply-migration/selects';
import { selectSupplySubAccounts } from '@/resources/supply-sub-accounts/internal/selectors';
import { nameSelector } from '@/resources/users/selectors';
import { Link, Router } from '@/routes';
import type { ApplicationState } from '@/store';
import * as complianceActions from '@/store/compliance/actions';
import * as partnerPageActions from '@/store/partner-page/actions';
import * as saasActions from '@/store/saas/actions';
import { closeGettingStartedModal } from '@/store/saas/actions';
import * as subAccountActions from '@/store/subaccount/actions';
import * as supplyContractActions from '@/store/supply-contracts/actions';
import * as supplyMigrationActions from '@/store/supply-migration/actions';

interface ControlCenterHomeStaticProps {
  query: Pick<
    ParsedUrlQuery,
    'publishSuccess' | 'feePaymentSuccess' | 'upgradedMigrationSuccess'
  >;
}

interface ControlCenterHomeStateProps {
  account?: TempAccount;
  clientName: string;
  gettingStartedVideo?: HippoVideo;
  integrations: IntegrationRow[];
  latestInvoices: InvoiceRow[];
  // loggedInUser: UserMetadata;
  saasSubscription?: Partial<SaasSubscription>;
  saasUserPlan?: SaasPlan;
  saasUserTrial?: Partial<SaasTrial>;
  selfServiceData: SelfServiceData;
  showControlCenterHomeGettingStartedBox?: boolean;
  showControlCenterHomeSetupBankingInformation?: boolean;
  showGettingStartedModal?: boolean;
  subAccounts: PortalSubAccount[];
  supplyMigration?: PortalSaasMigrationSetting;
}

interface ControlCenterHomeDispatchProps {
  closeModal: () => ActionType<typeof closeGettingStartedModal>;
}

export type ControlCenterHomeProps = ControlCenterHomeStaticProps &
  ControlCenterHomeStateProps &
  ControlCenterHomeDispatchProps;

const ControlCenterHome: React.FC = () => {
  const { query: queryUnpicked, replace } = useRouter();
  const account = useSelector(selectFirstAccount);
  const clientName = useSelector(nameSelector);
  const gettingStartedVideo = useSelector(
    (state: ApplicationState) =>
      state.tutorials.tutorialVideos[TutorialVideoType.GETTING_STARTED_VIDEO]
  );
  const integrations = useSelector(IntegrationsTableDataSelector);
  const invoices = useSelector(invoicesSelector);
  const latestInvoices = useSelector(latestInvoicesSelector);

  const saasSubscription = useSelector(selectSaasSubscription);
  const saasUserPlan = useSelector(saasUserPlanSelector);
  const saasUserTrial = useSelector(saasUserTrialSelector);
  const selfServiceData = useSelector(selfServiceDataSelector);
  const showGettingStartedModal = useSelector(
    selectSaasShowGettingStartedModal
  );
  const subAccounts = useSelector(selectSupplySubAccounts);
  const supplyMigration = useSelector(selectSaasMigrationSettings);

  const {
    showVideoTutorials,
    showGettingStartedTutorial,
    showHomepageGuide,
    hasCrmUserRole,
    shouldRedirectToWelcome,
    shouldSkipTrialExpiredPage,
    showControlCenterHomeGettingStartedBox,
    showControlCenterHomeSetupBankingInformation,
    showControlCenterHomeHeaderText,
    showControlCenterSidebarTrialBanner,
    showSidebarResources,
  } = useSelector(viewContextSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      partnerPageActions.partnerKeyRequestAction.request({ getAllByKey: true })
    );
    dispatch(supplyContractActions.getSupplyContractsAll.request({}));
    dispatch(saasActions.saasAllRequestAction.request({}));
    dispatch(complianceActions.myAccountComplianceRequestAction.request({}));

    if (
      showGettingStartedModal &&
      showVideoTutorials &&
      showGettingStartedTutorial
    ) {
      dispatch(
        getTutorialVideoDetails.request({
          type: TutorialVideoType.GETTING_STARTED_VIDEO,
        })
      );
    }

    dispatch(payableActions.payableRequestAction.request({}));
    dispatch(accountActions.accountRequestAction.request({}));
    dispatch(subAccountActions.supplySubAccountRequestAction.request({}));
    dispatch(
      supplyMigrationActions.getSettingsByAuthContextRequestAction.request({})
    );
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(saasUserTrial) && shouldRedirectToWelcome) {
      replace('/welcome');
    }
  }, [saasUserTrial]);

  if (hasCrmUserRole) {
    Router.replaceRoute('/performance-summary');

    return <></>;
  }

  const query = pick(queryUnpicked, [
    'publishSuccess',
    'feePaymentSuccess',
    'upgradedMigrationSuccess',
  ]);

  const closeModal = () => dispatch(closeGettingStartedModal());

  const isPaid = Boolean(selfServiceData.currentPlan);

  const freeTrialEndDate = saasUserTrial?.endDate;
  const freeTrialDaysLeft = getFreeTrialDaysLeft(freeTrialEndDate);
  const freeTrialExpired = freeTrialDaysLeft < 0;

  const isPermanent = isPermanentTrial(supplyMigration!);

  return (
    <>
      <CreateDrawer />
      <div className="self-service-home-scope">
        {showControlCenterHomeHeaderText && (
          <h1>
            Welcome, <strong>{clientName}</strong>!
          </h1>
        )}
        {showGettingStartedModal && gettingStartedVideo && (
          <GettingStartedModal
            closeModal={closeModal}
            title={`Welcome, ${clientName}!`}
            video={gettingStartedVideo}
          />
        )}
        {freeTrialExpired &&
        !saasSubscription &&
        !supplyMigration &&
        !shouldSkipTrialExpiredPage ? (
          <Layout>
            <SelfServiceHomeTrialExpired
              freeTrialDaysLeft={freeTrialDaysLeft}
            />
          </Layout>
        ) : (
          <LayoutWithSidebar
            sidebarContent={
              <RightSidebarContent
                freeTrialEndDate={freeTrialEndDate}
                hideFreeTrial={isPaid}
                isPermanent={isPermanent}
                showResources={showSidebarResources}
                showTrialBanner={showControlCenterSidebarTrialBanner}
                supplyMigration={supplyMigration}
              />
            }
          >
            {showHomepageGuide && (
              <div className="new-feature">
                Learn more with&nbsp;
                <Link route="/guide/home">
                  <a>tutorial</a>
                </Link>
              </div>
            )}
            <SelfServiceHomeRegular
              {...{
                account,
                clientName,
                closeModal,
                integrations,
                invoices,
                isPermanent,
                latestInvoices,
                query,
                saasUserPlan,
                selfServiceData,
                showControlCenterHomeGettingStartedBox,
                showControlCenterHomeSetupBankingInformation,
                subAccounts,
                supplyMigration,
              }}
            />
          </LayoutWithSidebar>
        )}
      </div>
    </>
  );
};

export default ControlCenterHome;
