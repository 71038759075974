import type { AccountAdjustment } from '@evenfinancial/finance-client';

import type { ApplicationState } from '@/store';

export const selectPayables = (state: ApplicationState) => state.payables.rows;

export const selectPayableAdjustments = (
  state: ApplicationState
): AccountAdjustment[] => state.payables.adjustments;

export const selectPayableAdjustmentsForDetailPage = (
  state: ApplicationState,
  id: number
) => state.payables.adjustmentsByLedgerItemId?.[id] ?? [];
