import * as React from 'react';

import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import type { AxiosResponse } from 'axios';
import { isURL } from 'class-validator';
import startCase from 'lodash/startCase';

import { IntegrationType } from '@evenfinancial/auth-client';
import { AccountTierType, ProductType } from '@evenfinancial/finance-client';
import type { SaasPlan, SaasTrial } from '@evenfinancial/finance-client';
import { ProductSubType } from '@evenfinancial/lead-client';

import { useControllable } from '@/components/form/portal-form/lib/use-controllable';
import { PortalFormFieldType } from '@/components/form/portal-form/types';
import type {
  FieldComponent,
  Options,
  PortalFormField,
} from '@/components/form/portal-form/types';
import type { IntegrationRow } from '@/resources/integrations/types';
import { disabledByTier } from '@/resources/saas/helpers';
import { integrationProductReachedLimit } from '@/resources/self-service/integrations/util';
import { partnerLogoFileSizeValidator } from '@/resources/self-service/integrations/validators';
import { getIntegrationCategory } from '@/resources/sub-accounts-shared/utils';
import { Link } from '@/routes';

export interface IntegrationCreateForm {
  integrationType: IntegrationType;
  partner: string;
  partnerLocationUrl: string;
  partnerLogo: { file: File; fileList: File[] };
  productType: ProductType;
}

const NameInputField: FieldComponent<string> = ({
  onChange,
  defaultValue = '',
  value,
}) => {
  const [nameValue, setNameValue] = useControllable({
    defaultValue,
    onChange,
    value,
  });

  return (
    <div className="integration-name-field">
      <Input
        maxLength={30}
        placeholder="This will be the integration name"
        value={nameValue}
        onChange={(event) => {
          setNameValue(
            event.currentTarget.value
              .toLowerCase()
              .replace(/[^a-z0-9]/g, '-')
              .replace(/-+/g, '-')
          );
        }}
      />
      <Text className="integration-name-field-text">
        {nameValue.length}/30 characters
      </Text>
    </div>
  );
};

export const integrationProductTypeOptions = [
  ProductType.Loan,
  ProductType.CreditCard,
  ProductType.LifeInsurance,
  ProductType.Savings,
  ProductSubType.StudentLoanRefinance,
  ProductSubType.AutomobileRefinance,
];

const mapValueToLabel = (item: ProductType | ProductSubType): string => {
  switch (item) {
    case ProductType.Loan:
      return 'Personal Loan';
    case ProductSubType.StudentLoanRefinance:
      return 'Student Loan Refinancing';
    case ProductSubType.AutomobileRefinance:
      return 'Auto Loan Refinancing';
    default:
      return startCase(item);
  }
};

export const integrationTypeOptions = [
  IntegrationType.MpsEmbedEven,
  IntegrationType.MpsPartnerPageEven,
];

export const integrationCreateFormFields = (
  integrations: IntegrationRow[],
  toggleIntegrationCreate: () => void,
  validateKey: (
    partnerPageKey: string
  ) => Promise<AxiosResponse<boolean> | undefined>,
  integrationType?: string,
  saasUserPlan?: SaasPlan,
  defaultProductType?: ProductType,
  lockProductType?: boolean,
  userTrial?: SaasTrial
): PortalFormField<IntegrationCreateForm>[] => {
  const isDisabled = integrationProductReachedLimit(
    integrations,
    integrationType,
    saasUserPlan,
    userTrial
  );

  /* TODO: Add a test/snapshot to prove this change */
  const disableEmbed = disabledByTier(
    AccountTierType.Starter,
    userTrial,
    saasUserPlan
  );

  return [
    {
      fieldClassName: 'integration-form-field',
      fieldKey: 'integrationKey',
      fieldProps: {
        Component: NameInputField,
      },
      label: 'Integration Key',
      name: 'partner',
      tooltip:
        'This is used to prefix all your integration urls, eg. for the integration key ‘apple’, your url would be fiona.com/partner/apple/loans',
      type: PortalFormFieldType.Custom,
      validation: [
        {
          message:
            'Integration Key is required and must be shorter than 30 characters',
          validator: (value: any) => {
            const pattern = /^[a-z0-9-]{1,30}$/;

            return value ? Boolean(value.match(pattern)) : false;
          },
        },
        {
          message: 'That name is already taken. Please try another one.',
          validator: async (value: string) => {
            const resp = await validateKey(value);

            return resp?.data ?? true;
          },
        },
      ],
    },
    {
      extra: disableEmbed ? (
        <>
          <Link route={'/account/upgrade'}>
            <a onClick={toggleIntegrationCreate}>Upgrade</a>
          </Link>{' '}
          to Growth Plan to enable Embed.
        </>
      ) : undefined,
      fieldClassName: 'integration-form-field',
      fieldProps: {
        options: integrationTypeOptions.map((item) => {
          const isEmbedLocked =
            disableEmbed && item === IntegrationType.MpsEmbedEven;

          return {
            disabled: isEmbedLocked,
            label: (
              <>
                {isEmbedLocked && <FontAwesomeIcon icon={faLock} />}{' '}
                {getIntegrationCategory(item)}
              </>
            ),
            value: item,
          };
        }),
        placeholder: 'Choose one',
      },
      label: 'Integration Type',
      name: 'integrationType',
      type: PortalFormFieldType.Select,
      validation: [
        {
          message: 'Please select integration type.',
          required: true,
        },
      ],
    },
    {
      fieldClassName: 'integration-form-field',
      fieldProps: {
        disabled: !integrationType || (defaultProductType && lockProductType),
        options: integrationProductTypeOptions.map((item) => {
          return {
            disabled: isDisabled,
            label: mapValueToLabel(item),
            value: item,
          };
        }) as Options,
        placeholder: 'Choose one',
      },
      label: 'Select Your Financial Product',
      name: 'productType',
      type: PortalFormFieldType.Select,
      validation: [
        {
          message: 'Please select financial product.',
          required: true,
        },
      ],
    },
    {
      fieldProps: {
        placeholder: 'Link to your website or blog',
      },
      label: `Where will you share your ${integrationType || 'Integration'}?`,
      name: 'partnerLocationUrl',
      tooltip: `Your website URL or article that links to the ${
        integrationType || 'Integration'
      }.`,
      type: PortalFormFieldType.Input,
      validation: [
        {
          message: 'Please input a link.',
          required: true,
        },
        {
          message: 'Link must be a valid URL',
          validator: (value: string) => {
            return isURL(value, {
              require_host: false,
              require_protocol: false,
              require_valid_protocol: false,
            });
          },
        },
      ],
    },
    {
      extra: 'Formats: .jpeg .png .gif .svg',
      fieldClassName: 'integration-upload-field thumb-no-click',
      fieldProps: {
        accept: 'image/png, image/jpeg, image/gif, image/svg+xml',
        allowedFilesNumber: 1,
        multiple: false,
      },
      label: 'Upload your logo',
      name: 'partnerLogo',
      tooltip:
        'Logo image that appears on the Partner Page header. Image should be 90px high, width is flexible. Supported formats are jpeg, png or gif.',
      type: PortalFormFieldType.Upload,
      validation: [
        {
          message: 'You must upload an image',
          validator: (value: any) => {
            return value ? value.fileList.length === 1 : false;
          },
        },
        partnerLogoFileSizeValidator,
      ],
    },
  ];
};
