import * as React from 'react';

import Modal from 'antd/lib/modal';

export interface ConfirmationModalProps extends React.PropsWithChildren<{}> {
  cancelText?: string;
  className?: string;
  okText?: string;
  onCancel: () => void;
  onOk: () => void;
  title?: string;
  visible: boolean;
  width?: number;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  children,
  className,
  okText,
  cancelText,
  visible,
  onOk,
  onCancel,
  width,
}) => {
  return (
    <Modal
      centered
      cancelText={cancelText}
      className={className}
      okText={okText}
      title={title || 'Are you sure?'}
      visible={visible}
      width={width || 550}
      onCancel={onCancel}
      onOk={onOk}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
