import * as React from 'react';
import { useState } from 'react';

import Spin from 'antd/lib/spin';
import classNames from 'classnames';

interface HippoVideoEmbedProps {
  embedUrl: string;
  height?: string | number;
  width?: string | number;
}

const HippoVideoEmbed = ({
  embedUrl,
  width = '100%',
  height = 450,
}: HippoVideoEmbedProps) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="hippo-embed-frame-wrapper" style={{ height, width }}>
      {loading && <Spin className="loading-spinner" size="large" />}
      <iframe
        allowFullScreen
        className={classNames('hippo-embed-frame', loading && 'loading')}
        frameBorder="0"
        height={height}
        scrolling="no"
        src={`${embedUrl}?autoplay=false`}
        width={width}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default HippoVideoEmbed;
