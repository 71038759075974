import * as React from 'react';
import { useSelector } from 'react-redux';

import type { PortalSaasMigrationSetting } from '@portal/api-models';
import { selectPermissions } from '@portal/store/dist/user/selectors';
import { FreeTrialBanner, QuickLinks, UpgradeBanner } from '@portal/ui-lib';

import { getFreeTrialDaysLeft } from '@/resources/saas/helpers';
import { Router } from '@/routes';

import { isPermanentTrial } from '../../resources/supply-migration/helpers';

export interface RightSidebarContentProps {
  freeTrialEndDate?: string;
  hideFreeTrial?: boolean;
  isPermanent?: boolean;
  showResources?: boolean;
  showTrialBanner?: boolean;
  supplyMigration?: PortalSaasMigrationSetting;
}

export const RightSidebarContent: React.FC<RightSidebarContentProps> = ({
  hideFreeTrial,
  freeTrialEndDate,
  supplyMigration,
  isPermanent,
  showTrialBanner,
  showResources,
}) => {
  const freeTrialDaysLeft = getFreeTrialDaysLeft(freeTrialEndDate);
  const { showRebrandGuideAndAssetsLink } = useSelector(selectPermissions);

  return (
    <>
      {!hideFreeTrial && showTrialBanner && (
        <FreeTrialBanner
          freeTrialDaysLeft={freeTrialDaysLeft}
          isPermanent={isPermanent}
        />
      )}
      {!hideFreeTrial && supplyMigration && !isPermanent && (
        <UpgradeBanner
          monthlyFee={supplyMigration?.monthlyFee}
          onClick={() => {
            supplyMigration && !isPermanentTrial(supplyMigration)
              ? Router.push('/account/upgrade')
              : Router.push('/account/subscription');
          }}
        />
      )}
      <QuickLinks
        showRebrandGuideAndAssetsLink={showRebrandGuideAndAssetsLink}
        showResources={showResources ?? hideFreeTrial}
      />
    </>
  );
};
